
<template>
    <div>
        <div class="listAll">
            <el-image src="http://222.168.10.38:8077/cczcimage/20231016/7209273a57c942828e58407cd805235a.png" class="list_top_img"></el-image>
            <el-image src="http://222.168.10.38:8077/cczcimage/20231012/90935cdbed384074a786b5e8953f7c7d.png" class="list_top_img_min"></el-image>
            <div class="list_title">仲裁下载</div>
            <div class="list">
                <div class="list_item" v-for="(item, index) in fileList" :key='index' @click="down(item.url)">
                    <div class="list_item_title">{{item.name}}</div>
                    <div class="list_item_time">[下载]</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data () {
            return {
                params: {
                    page: 1,
                    limit: 100,
                    type: '',
                    total: 0
                },
                fileList: []
            }
        },

        components: {

        },

        watch: {

        },

        mounted() {
            this.getFileList();
        },

        methods: {
            //获取新闻列表
            getFileList() {
                this.$axios.get(this.$httpUrl + '/CczcFile/cczcfile/page',{
                    params: this.params
                }).then(res => {
                    if (res.data.code != 0) {
                        return this.$message.error(res.data.data.msg);
                    }
                    this.fileList = res.data.data.list
                })
            },

            down(url) {
                window.open(url)
            }
        }
    }
</script>

<style scoped>
    .listAll {
        position: relative;
        text-align: center;
    }

    .list_top_img {
        margin-top: 0px;
        margin-left: 0px;
        width: 100%;
        height: auto;
    }

    .list_top_img_min{
        position: absolute;
        width: 25%;
        height: auto;
        top: 200px;
        left: 37.5%;
    }

    .list_title {
        margin-top: 50px;
        text-align: center;
        font-size: 30px;
        font-weight: 700;
    }

    .typeList {
        display: flex;
        width: 100%;
        height: 80px;
        background-color: white;
        line-height: 80px;
        font-size: 14px;
        font-weight: 700;
    }

    .typeItem {
        margin-right: 20px;
        text-align: left;
        cursor: pointer;
    }

    .list {
        margin-top: 50px;
        width: 100%;
        margin-bottom: 50px;
    }

    .list_item {
        position: relative;
        width: 40%;
        left: 30%;
        height: 60px;
        line-height: 60px;
        cursor: pointer;
    }

    .list_item:hover .list_item_title {
        color: #007cc7;
    }

    .list_item_title {
        position: absolute;
        width: 80%;
        font-size: 14px;
        font-weight: 700;
        left: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
    }

    .list_item_time {
        position: absolute;
        width: 20%;
        font-size: 14px;
        font-weight: 700;
        right: 0px;
        text-align: right;
    }
</style>
